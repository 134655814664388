// const user = JSON.parse(localStorage.getItem('user')) ?? []
// const abilities = user.user?.ability ?? []
// import dashboardadmin from './dashboardadmin'
// import dashboardinstance from './dashboardinstance'
// import instance from './instance'
// import packet from './packet'
// import slider from './slider'
// import packet from './packet'
// import violations from './violation'
// const ability = localStorage.getItem('userAbility')

export default () => {
  const abilities = JSON.parse(localStorage.getItem('userAbility')) ?? []
  const elements = []
  abilities.forEach(element => {
    const push = element.subject.toLowerCase()
    // eslint-disable-next-line import/no-dynamic-require
    if (push !== 'appuserview' && push !== 'appuserfaq') {
      // eslint-disable-next-line import/no-dynamic-require
      elements.push(require(`./${push}`).default ?? [])
    }
  })

  return [...elements]
}

// export default [dashboardadmin, dashboardinstance, instance, packet, slider]
