const instance = [
  {
    path: '/instance',
    name: 'instance',
    component: () => import('@/views/instance/Instance.vue'),
    meta: {
      layout: 'content',
      resource: 'InstanceAdmin',
      action: 'read',
    },
  },
  {
    path: '/instance/instance-spod/:uuid',
    name: 'instance-spod-admin',
    component: () => import('@/views/instance/InstanceSpod.vue'),
    meta: {
      layout: 'content',
      resource: 'InstanceAdmin',
      action: 'read',
    },
  },
  {
    path: '/instance-spod/',
    name: 'instance-spod',
    component: () => import('@/views/dashboards/instance/InstanceSpod.vue'),
    meta: {
      layout: 'content',
      resource: 'SpodInstance',
      action: 'read',
    },
  },
  {
    path: '/instance/statistic-spod/:instance_uuid/:spod_id',
    name: 'statistic-spod-show',
    component: () => import('@/views/instance/InstanceStatSpodShow.vue'),
    meta: {
      layout: 'content',
      resource: 'InstanceAdmin',
      action: 'read',
    },
  },
]
export default instance
