import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'

// const firebaseConfig = {
//   apiKey: 'AIzaSyBYJ82fRiA5RcFKBFNrlcVHPkq5T-Nrfa0',
//   authDomain: 'sekolah-notif.firebaseapp.com',
//   projectId: 'sekolah-notif',
//   storageBucket: 'sekolah-notif.appspot.com',
//   messagingSenderId: '258963385011',
//   appId: '1:258963385011:web:dbab1373fc380104ad1ec6',
// }

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
}

const firebaseApp = initializeApp(firebaseConfig)

const messaging = getMessaging(firebaseApp)
export default messaging
