import repositories from '@/repositories'

export default {
  recapEmployee(uuid, params = {}) {
    return repositories.RecapRepository.recapEmployee(uuid, params).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
  recapStudent(uuid, params = {}) {
    return repositories.RecapRepository.recapStudent(uuid, params).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
  recapUser(uuid, params = {}) {
    return repositories.RecapRepository.recapUser(uuid, params).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
  recap(uuid, params = {}) {
    return repositories.RecapRepository.recap(uuid, params).then(
      res => Promise.resolve(res),
      err => Promise.reject(err),
    )
  },
}
