import '@/@fake-db/db'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import { abilitiesPlugin } from '@casl/vue'
import CKEditor from 'ckeditor4-vue'
import Vue from 'vue'
import VueSocialauth from 'vue-social-auth'
import * as VueGoogleMaps from 'vue2-google-maps'
// import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'
// import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'
import App from './App.vue'
import firebaseMessaging from './firebase'
import ability from './plugins/acl/ability'
import vuetify from './plugins/vuetify'
import router from './router'
import services from './services'
import store from './store'

Vue.prototype.$messaging = firebaseMessaging
Vue.prototype.$services = services
Vue.config.productionTip = false
Vue.use(CKEditor)
Vue.use(abilitiesPlugin, ability)
Vue.use(VueSocialauth, {
  providers: {
    google: {
      clientId: process.env.VUE_APP_SOCIAL_GOOGLE_CLIENT_ID,
      redirectUri: 'http://localhost:8080/login', // Your client app URL
      popupOptions: {
        top: 120,
        left: window.innerWidth,
        width: 500,
      },
    },
  },
})
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBF9zvtQln-rDzESGWr4pI3OGW6z4xHQWU',
    libraries: 'places', // Optional libraries
  },
  installComponents: true,
})
// Vue.use(VuetifyGoogleAutocomplete, {
//   /*
//     not used as loaded with component
//     apiKey: key,
//   */
//   vueGoogleMapsCompatibility: true,
// })

new Vue({
  router,
  store,
  i18n,
  vuetify,
  ability,
  VueGoogleMaps,
  render: h => h(App),
}).$mount('#app')
