import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'

import apps from './apps'
import attendance from './attendance'
import bankicon from './bankicon'
import bookrequest from './bookrequest'
import classes from './classes'
import dailybook from './dailybook'
import dashboard from './dashboard'
import employee from './employee'
import faq from './faq'
import instance from './instance'
import library from './library'
import packet from './packet'
import profile from './profile'
import slider from './slider'
import snapbook from './snapbook'
import statisticspod from './statisticspod'
import student from './student'
import user from './user'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const user = JSON.parse(localStorage.getItem('user'))
      // store.dispatch('session/saveSession', user)
      if (user?.user?.role === 'admin') return { name: 'dashboard-admin' }
      if (user?.user?.role === 'company') return { name: 'dashboard-instance' }

      return { name: 'auth-login', query: to.query }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/misc-not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  // {
  //   path: '/register',
  //   name: 'auth-register',
  //   component: () => import('@/views/Register.vue'),
  //   meta: {
  //     layout: 'blank',
  //     resource: 'Public',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password/:token',
    name: 'auth-reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/success-verification',
    name: 'success-verification',
    component: () => import('@/views/SuccessVerification.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/pivot',
    name: 'pivot',
    component: () => import('@/views/components/DataTablePaginationPivot.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  ...apps,
  ...attendance,
  ...bankicon,
  ...classes,
  ...dailybook,
  ...dashboard,
  ...employee,
  ...instance,
  ...library,
  ...packet,
  ...profile,
  ...slider,
  ...snapbook,
  ...student,
  ...user,
  ...faq,
  ...classes,
  ...statisticspod,
  ...bookrequest,

  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, _, next) => {
  const publicPages = [
    'auth-login',
    // 'auth-register',
    'auth-reset-password',
    'auth-forgot-password',
    'success-verification',
    'pivot',
  ]
  const authRequired = !publicPages.includes(to.name)
  const user = localStorage.getItem('user')
  const ability = JSON.parse(localStorage.getItem('userAbility'))
  const isLoggedIn = user && ability
  // const isLoggedIn = user

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (authRequired && !isLoggedIn) return next('/login')

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
