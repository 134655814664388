const profile = [
  {
    path: '/profile-instance',
    name: 'profile-instance',
    component: () => import('@/views/profile/Profile.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'ProfileInstance',
    },
  },
]

export default profile
