import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/admin/packages/library'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  get(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  update(packages, uuid) {
    return http.post(`${resource}/${uuid}?_method=PATCH`, packages)
  },
  add(packages) {
    return http.post(`${resource}`, packages)
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
}
