const employee = [
  {
    path: '/employee',
    name: 'employee',
    component: () => import('@/views/employee/Employee.vue'),
    meta: {
      layout: 'content',
      resource: 'EmployeeInstance',
      action: 'read',
    },
  },
]
export default employee
