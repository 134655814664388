const bookrequest = [
  {
    path: '/book-reqeust',
    name: 'book-request',
    component: () => import('@/views/book-request/BookRequest.vue'),
    meta: {
      layout: 'content',
      resource: 'BookRequestInstance',
      action: 'read',
    },
  },
]
export default bookrequest
