import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/company'
const resource2 = '/api/v1/admin/company/check-id'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  get(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  update(news, uuid) {
    return http.post(`${resource}/${uuid}?_method=PATCH`, news)
  },
  add(news) {
    return http.post(`${resource}`, news)
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
  checkId(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource2}${paramsString}`)
  },
}
