import repo from '@/repositories'

const repositories = {
  auth: repo.AuthRepository,
  dashboardadmin: repo.DashboardRepository,
  dashboardinstance: repo.DashboardInstanceRepository,
  district: repo.DistrictRepository,
  dailybook: repo.DailyBookRepository,
  snapbook: repo.SnapBookRepository,
  village: repo.VillageRepository,
  instancesetting: repo.InstanceSettingRepository,
  package: repo.PackageRepository,
  instance: repo.InstanceRepository,
  transaction: repo.TransactionRepository,
  category: repo.CategoryRepository,
  categorylibrary: repo.CategoryLibraryRepository,
  instancelibrary: repo.InstanceLibraryRepository,
  library: repo.LibraryRepository,
  order: repo.InstanceOrderRepository,
  packagelibrary: repo.PackageLibraryRepository,
  province: repo.ProvinceRepository,
  regency: repo.RegencyRepository,
  slider: repo.SliderRepository,
  spod: repo.SpodRepository,
  statisticspod: repo.StatisticSpodRepository,
  transactioncompany: repo.TransactionCompanyRespository,
  bankicon: repo.BankIconRepository,
  bankiconpost: repo.BankIconPostRepository,
  tag: repo.TagIconRepository,
  student: repo.StudentRepository,
  classes: repo.ClassRepository,
  employee: repo.EmployeeRepository,
  usercompany: repo.UserCompanyRepository,
  attendance: repo.AttendancesRepository,
  faq: repo.FaqRepository,
  faquser: repo.FaqUserRepository,
  gallery: repo.GalleryRepository,
  user: repo.UserRepository,
  layout: repo.LayoutRepository,
  bookrequest: repo.BookRequestRepository,
}

export default {
  add(service, data) {
    try {
      return repositories[service.toLowerCase()].add(data).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service doesn't has add method` })
    }
  },
  update(service, data, uuid) {
    try {
      return repositories[service.toLowerCase()].update(data, uuid).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service has no update method` })
    }
  },

  async list(service, params = {}) {
    try {
      return repositories[service.toLowerCase()].list(params).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service has no list method` })
    }
  },

  get(service, uuid, params = {}) {
    try {
      return repositories[service.toLowerCase()].get(uuid, params).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service has no get method` })
    }
  },

  destroy(service, uuid) {
    try {
      return repositories[service.toLowerCase()].destroy(uuid).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service has no delete method` })
    }
  },
  checkOrder(service, params = {}) {
    try {
      return repositories[service.toLowerCase()].checkOrder(params).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service has no checkOrder method` })
    }
  },
  checkId(service, params = {}) {
    try {
      return repositories[service.toLowerCase()].checkId(params).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service has no checkId method` })
    }
  },
}
