export default (time, separator = '-', incTime = true, monthType = false) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const objTime = new Date(time)
  const year = objTime.getFullYear()
  const month = `0${objTime.getMonth()}`
  const date = `0${objTime.getDate()}`
  const hours = objTime.getHours()
  const minutes = objTime.getMinutes()
  if (incTime) {
    return `${year}${separator}${month.slice(-2)}${separator}${date.slice(-2)} ${hours}:${minutes}`
  }

  if (monthType) {
    return `${year}${separator}${monthNames[month.slice(-1)]}${separator}${date.slice(-2)}`
  }

  return `${year}${separator}${month.slice(-2)}${separator}${date.slice(-2)}`
}
