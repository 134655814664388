import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/faq'
// const resource2 = '/api/v1/faq-group'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  //   listNew(params = {}) {
  //     const paramsString = paramsConverter(params)

  //     return http.get(`${resource2}${paramsString}`)
  //   },
  add(faq) {
    return http.post(`${resource}`, faq)
  },
  get(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  update(faq, uuid) {
    return http.post(`${resource}/${uuid}?_method=PATCH`, faq)
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
}
