const user = [
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/user/User.vue'),
    meta: {
      layout: 'content',
      resource: 'UserInstance',
      action: 'read',
    },
  },
]
export default user
