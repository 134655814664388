const slider = [
  {
    path: '/slider',
    name: 'slider',
    component: () => import('@/views/slider/Slider.vue'),
    meta: {
      layout: 'content',
      resource: 'SliderAdmin',
      action: 'read',
    },
  },
  {
    path: '/slider-instance',
    name: 'slider-instance',
    component: () => import('@/views/slider/SliderInstance.vue'),
    meta: {
      layout: 'content',
      resource: 'SliderInstance',
      action: 'read',
    },
  },
]
export default slider
