const dashboard = [
  {
    path: '/dashboards/admin',
    name: 'dashboard-admin',
    component: () => import('@/views/dashboards/admin/Admin.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardAdmin',
      action: 'read',
    },
  },
  {
    path: '/dashboards/instance',
    name: 'dashboard-instance',
    component: () => import('@/views/dashboards/instance/Instance.vue'),
    // component: () => import('@/views/pages/cards/card-chart/cards/TotalProfit.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardInstance',
      action: 'read',
    },
  },
]
export default dashboard
