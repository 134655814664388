const packet = [
  {
    path: '/packet',
    name: 'packet',
    component: () => import('@/views/packet/Packet.vue'),
    meta: {
      layout: 'content',
      resource: 'PacketAdmin',
      action: 'read',
    },
  },
]
export default packet
