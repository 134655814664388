const statisticspod = [
  {
    path: '/statistic-spod',
    name: 'statistic-spod',
    component: () => import('@/views/dashboards/instance/InstanceStatSpod.vue'),
    meta: {
      layout: 'content',
      resource: 'StatisticSpod',
      action: 'read',
    },
  },
  {
    path: '/instance-spod/:spod_id',
    name: 'statistic-instance-spod-show',
    component: () => import('@/views/dashboards/instance/InstanceStatSpodShow.vue'),
    meta: {
      layout: 'content',
      resource: 'StatisticSpod',
      action: 'read',
    },
  },
]
export default statisticspod
