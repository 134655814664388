import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/company/dashboard'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
}
