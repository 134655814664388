const snapbook = [
  {
    path: '/snap-book',
    name: 'snap-book',
    component: () => import('@/views/snap-book/SnapBook.vue'),
    meta: {
      layout: 'content',
      resource: 'SnapBookAdmin',
      action: 'read',
    },
  },
]

export default snapbook
